import {observer} from "mobx-react";
import {
    Container,
    ContainerPermission,
    Content,
    ContentPermission,
    TextPermission,
} from "./styled";
import Switch from "@dropDesk/presentation/components/switch";
import React from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import {Tooltip} from "antd";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";


const TicketConfiguration = observer(
    ({
         loading,
         handleSubmit,
         companyConfiguration,
         onUpdate
     }: {
        loading: boolean,
        handleSubmit: () => void,
        onUpdate: (companyConfiguration: CompanyConfigurationEntity) => void,
        companyConfiguration: CompanyConfigurationEntity
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const handleAllChangesTicketConfiguration = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _configurationTicket = companyConfiguration.copyWith({
                ticket: companyConfiguration.ticket.copyWith({
                    [event.target.name]:
                        (event.target.type === "checkbox" || event.target.type === "switch") ?
                            event.target.checked : event.target.value
                })
            });
            onUpdate(_configurationTicket);
        };


        return (
            <Container heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <Content background={colors.onBackground} border={colors.border}>
                    <HeaderConfiguration
                        letter={"Configurações de Atendimento"}
                        disabled={loading}
                    />
                    <ContainerPermission>
                        <ContentPermission>
                            <Switch
                                disabled={loading}
                                id={'useRatingTicket'}
                                name={'useRatingTicket'}
                                checked={companyConfiguration.ticket.useRatingTicket}
                                onChange={handleAllChangesTicketConfiguration}
                            />
                            <Tooltip
                                title={`Ao selecionar esta configuração, ao finalizar o atendimento, o cliente poderá avaliar o atendimento, a avaliação ficará disponível até 3 dias após o fechamento.`}>
                                <TextPermission onClick={() => {
                                }} color={colors.text}>Permitir o cliente avaliar o atendimento
                                    <DropDeskIcon
                                        color={colors.hint}
                                        icon={ICONS_DROPDESK.interrogationOutline}/>
                                </TextPermission>
                            </Tooltip>
                        </ContentPermission>
                        <ContentPermission>
                            <Switch
                                disabled={loading}
                                id={'informReasonCancellation'}
                                name={'informReasonCancellation'}
                                checked={companyConfiguration.ticket.informReasonCancellation}
                                onChange={handleAllChangesTicketConfiguration}
                            />
                            <Tooltip
                                title={`Ao selecionar esta configuração, será obrigatório que seu atendente informe o motivo do cancelamento, ao cancelar um atendimento.`}>
                                <TextPermission onClick={() => {
                                }} color={colors.text}>Obrigatório descrever Motivo do Cancelamento no
                                    Atendimento
                                    <DropDeskIcon
                                        color={colors.hint}
                                        icon={ICONS_DROPDESK.interrogationOutline}/>
                                </TextPermission>
                            </Tooltip>
                        </ContentPermission>
                        <ContentPermission>
                            <Switch
                                disabled={loading}
                                id={'informResolutionTicket'}
                                name={'informResolutionTicket'}
                                checked={companyConfiguration.ticket.informResolutionTicket}
                                onChange={handleAllChangesTicketConfiguration}
                            />
                            <Tooltip
                                title={`Ao selecionar esta configuração, será obrigatório que seu atendente informe a solução do atendimento, ao fechar um atendimento.`}>
                                <TextPermission onClick={() => {
                                }} color={colors.text}>Obrigatório descrever a resolução do Atendimento
                                    <DropDeskIcon
                                        color={colors.hint}
                                        icon={ICONS_DROPDESK.interrogationOutline}/>
                                </TextPermission>
                            </Tooltip>
                        </ContentPermission>

                        <ContentPermission>
                            <Switch
                                disabled={loading}
                                id={'useProblemStatic'}
                                name={'useProblemStatic'}
                                checked={companyConfiguration.ticket.useProblemStatic}
                                onChange={handleAllChangesTicketConfiguration}
                            />
                            <Tooltip
                                title={`Ao marcar esta opção, habilita um menu na abertura do atendimento onde você poderá inserir um problema pré definido, igualmente será no painel do cliente.`}>
                                <TextPermission onClick={() => {
                                }} color={colors.text}>Utilizar problema estático na abertura de
                                    atendimentos
                                    <DropDeskIcon
                                        color={colors.hint}
                                        icon={ICONS_DROPDESK.interrogationOutline}/>
                                </TextPermission>
                            </Tooltip>
                        </ContentPermission>
                    </ContainerPermission>

                    <FooterConfiguration
                        disabled={loading}
                        loading={loading}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={handleSubmit}
                    />
                </Content>

            </Container>
        )
    })
export default TicketConfiguration;
