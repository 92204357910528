import {Container, Text, TextLink} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {observer} from "mobx-react";
import Version from "@dropDesk/presentation/components/version";
import React from "react";


const Footer = observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <Container>
            <Text color={colors.text}>Precisa de ajuda?
                <TextLink
                    color={colors.textLink}
                    href={`${process.env.LINK_SUPPORT}`}
                    target="_blank"
                    style={{color: colors.textLink}}
                >&nbsp;&nbsp;Fale com o nosso suporte</TextLink>
            </Text>
            <Version/>
        </Container>
    );
});
export default Footer;
