import styled from "styled-components";

type TextType = {
    color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Text = styled.span<TextType>`
  font-size: 16px;
  color: ${props => props.color};
  font-style: italic;
`;

export const TextBold = styled.span<TextType>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
  font-style: italic;
`;
