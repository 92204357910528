import styled from "styled-components";

type TextType = {
    color: string;
}

export const Container = styled.div`
  padding: 8px 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Text = styled.span<TextType>`
  font-size: 14.5px;
  color: ${props => props.color};
  text-align: left;
`;

export const TextLink = styled.a<TextType>`
  font-size: 14.5px;
  color: ${props => props.color};

  &:hover {
    text-decoration: underline;
  }
`;
