import React, {useEffect, useState} from "react";
import {
    Container,
    ContainerGraphics,
    Content,
    ContentExportImport, ContentFilter,
    ContentInput,
    ContentInputAndFilter,
} from "./styled";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import SelectRangeDate from "@dropDesk/presentation/components/dates/select_range_date";
import GraphicBar from "@dropDesk/presentation/components/graphics/graphic_horizontal_bar";
import Task from "@dropDesk/presentation/pages/task/ui";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    ReportTicketController
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/controller/report_ticket.controller";
import HeaderConfirmationAccount from "@dropDesk/presentation/routes/header_confirmation_account";
import Version from "@dropDesk/presentation/components/version";

const Dashboard = (observer(() => {
    const controller = useInjection(ReportTicketController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const [visibleHeaderConfirmationAccount, setVisibleHeaderConfirmationAccount] = useState(true);

    useEffect(() => {
        controller.initializeDashboard();
    }, []);

    return (

        <Container background={colors.onBackground}>
            {appController.user && visibleHeaderConfirmationAccount && !appController.user.verified && appController.user.isAttendant &&
                <HeaderConfirmationAccount
                    currentUser={appController.user!}
                    onClickClose={() => setVisibleHeaderConfirmationAccount(false)}
                />
            }
            <ContentFilter background={colors.onBackground}>
                <ContentInputAndFilter>
                    <ContentInput>
                        <SelectRangeDate
                            size={37}
                            label={'Período'}
                            allowClear={true}
                            value={controller.period}
                            onChange={(arrayDate, label) => {
                                controller.setPeriod(arrayDate, label);
                                controller.getReportTicket();
                            }}
                            labelPeriodCustom={controller.labelPeriod}
                        />
                    </ContentInput>
                </ContentInputAndFilter>

                <ContentExportImport>

                </ContentExportImport>
            </ContentFilter>
            <Content>
                <ContainerGraphics>
                    <GraphicBar
                        data={controller.chartStatus ?? []}
                        description={"Atendimento Por Status"}
                        loading={controller.loading}
                        hasData={controller.hasData(controller.chartStatus)}
                        showGroupedData={controller.showGroupedData}
                        showIconGrouped={true}
                        toolTipDescriptionGroupedData={controller.showGroupedData ? 'Clique para desagrupar os dados' : 'Clique para agrupar os dados'}
                        onClickUngroupedData={() => controller.setShowGroupedData()}
                    />

                    <GraphicBar
                        data={controller.chartPriority ?? []}
                        description={"Atendimentos por Prioridade"}
                        loading={controller.loading}
                        hasData={controller.hasData(controller.chartPriority)}
                    />

                </ContainerGraphics>

                <ContainerGraphics>
                    <GraphicBar
                        data={controller.chartLocation ?? []}
                        description={"Atendimentos Por origem Interno/Externo"}
                        loading={controller.loading}
                        hasData={controller.hasData(controller.chartLocation)}
                    />

                    <GraphicBar
                        data={controller.chartResolutionTime ?? []}
                        description={"Tempo de Solução dos Atendimentos"}
                        loading={controller.loading}
                        hasData={controller.hasData(controller.chartResolutionTime)}
                    />

                </ContainerGraphics>
                <Task/>
                <Version/>
            </Content>
        </Container>
    );
}))
export default Dashboard;
